import { localeCs, localeEn, localeSk } from "@olc/vue-grid";
import csMessages from "./src/lang/cs.json";
import skMessages from "./src/lang/sk.json";
import enMessages from "./src/lang/en.json";
import deepmerge from "deepmerge";

const region = process.env.NUXT_PUBLIC_REGION || "cz";
const enConfig = {
  datetimeFormats: {
    en: {
      short: {
        year: "numeric", month: "short", day: "numeric"
      },
      long: {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      }
    }
  },
  messages: {
    en: enMessages,
  },
  ...localeEn,
};
let regionLocalConfig = {};

if (region === "cz") {
  regionLocalConfig = {
    datetimeFormats: {
      cs: {
        short: {
          year: "numeric", month: "short", day: "numeric"
        },
        long: {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        }
      }
    },
    messages: {
      cs: csMessages,
    },
    ...localeCs,
  };
} else if (region === "sk") {
  regionLocalConfig = {
    messages: {
      sk: skMessages,
    },
    ...localeSk,
  };
}

//@ts-ignore
export default defineI18nConfig(() => ({
  ...deepmerge(enConfig, regionLocalConfig),
  fallbackLocale: {
    sk: ["cs", "en"],
    default: ["en"]
  }
}));
